import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.join.js";
import ApiService from "@/core/services/api.service";
import objectPath from "object-path";
export var UpdateUnit = function UpdateUnit(params) {
  return new Promise(function (resolve, reject) {
    ApiService.setHeader();
    ApiService.post("unit", params).then(function (_ref) {
      var data = _ref.data;
      resolve(data);
    }).catch(function (_ref2) {
      var response = _ref2.response;
      reject(response);
    });
  });
};
export var DeleteUnit = function DeleteUnit(uuid) {
  return new Promise(function (resolve, reject) {
    ApiService.setHeader();
    ApiService.delete("unit/".concat(uuid)).then(function (_ref3) {
      var data = _ref3.data;
      resolve(data);
    }).catch(function (_ref4) {
      var response = _ref4.response;
      reject(response);
    });
  });
};
export var UpdateBrand = function UpdateBrand(params) {
  return new Promise(function (resolve, reject) {
    ApiService.setHeader();
    ApiService.post("brand", params).then(function (_ref5) {
      var data = _ref5.data;
      resolve(data);
    }).catch(function (_ref6) {
      var response = _ref6.response;
      reject(response);
    });
  });
};
export var DeleteBrand = function DeleteBrand(uuid) {
  return new Promise(function (resolve, reject) {
    ApiService.setHeader();
    ApiService.delete("brand/".concat(uuid)).then(function (_ref7) {
      var data = _ref7.data;
      resolve(data);
    }).catch(function (_ref8) {
      var response = _ref8.response;
      reject(response);
    });
  });
};
export var UpdateManufacturer = function UpdateManufacturer(params) {
  return new Promise(function (resolve, reject) {
    ApiService.setHeader();
    ApiService.post("manufacturer", params).then(function (_ref9) {
      var data = _ref9.data;
      resolve(data);
    }).catch(function (_ref10) {
      var response = _ref10.response;
      reject(response);
    });
  });
};
export var DeleteManufacturer = function DeleteManufacturer(uuid) {
  return new Promise(function (resolve, reject) {
    ApiService.setHeader();
    ApiService.delete("manufacturer/".concat(uuid)).then(function (_ref11) {
      var data = _ref11.data;
      resolve(data);
    }).catch(function (_ref12) {
      var response = _ref12.response;
      reject(response);
    });
  });
};
export var validationError = function validationError(_ref13) {
  var data = _ref13.data;
  var errors = [];
  objectPath.del(data, "debug");

  for (var row in data) {
    errors.push({
      model: true,
      message: data[row].join(", ")
    });
  }

  return errors;
};