"use strict";

var KTLayoutHeader = function () {
  // Private properties
  var _element;

  var _elementForMobile;

  var _object; // Private functions
  // Get Height


  var _getHeight = function _getHeight() {
    var height = 0;

    if (_element) {
      height = KTUtil.actualHeight(_element) + 1;
    }

    return height;
  }; // Get Height


  var _getHeightForMobile = function _getHeightForMobile() {
    var height;
    height = KTUtil.actualHeight(_elementForMobile);
    return height;
  }; // Public Methods


  return {
    init: function init(id, idForMobile) {
      _element = KTUtil.getById(id);
      _elementForMobile = KTUtil.getById(idForMobile);

      if (!_element) {
        return;
      }
    },
    isFixed: function isFixed() {
      return KTUtil.hasClass(KTUtil.getBody(), 'header-fixed');
    },
    isFixedForMobile: function isFixedForMobile() {
      return KTUtil.hasClass(KTUtil.getBody(), 'header-mobile-fixed');
    },
    getElement: function getElement() {
      return _element;
    },
    getElementForMobile: function getElementForMobile() {
      return _elementForMobile;
    },
    getHeader: function getHeader() {
      return _object;
    },
    getHeight: function getHeight() {
      return _getHeight();
    },
    getHeightForMobile: function getHeightForMobile() {
      return _getHeightForMobile();
    }
  };
}();

export default KTLayoutHeader;