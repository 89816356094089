import _defineProperty from "D:/Development/Laravel/genic-drive-updated/frontend/node_modules/@babel/runtime/helpers/esm/defineProperty.js";

var _actions, _mutations;

import "core-js/modules/es.array.map.js";
// import ApiService from '@/core/services/api.service';
import { toSafeInteger, isArray } from "lodash"; // action types
// mutation types

export var ERROR = "error";
export var MESSAGE = "message";
export var SEARCH_ENABLED = "searchEnabled";
export var SEARCH_TITLE = "searchTitle";
export var SET_ERROR = "setError";
export var SET_MESSAGE = "setMessage";
export var SET_SEARCH_ENABLED = "setSearchEnabled";
export var SET_SEARCH_TITLE = "setSearchTitle";
export var SET_UPLOAD_STATUS = "setUploadStatus";
var state = {
  errors: [],
  messages: [],
  search_enabled: false,
  search_title: null,
  upload_status: false
};
var getters = {
  errors: function errors(state) {
    return state.errors;
  },
  canUpload: function canUpload(state) {
    return state.upload_status;
  },
  isUploadDisabled: function isUploadDisabled(state) {
    return !state.upload_status;
  },
  messages: function messages(state) {
    return state.messages;
  },
  searchEnabled: function searchEnabled(state) {
    return state.search_enabled;
  },
  searchTitle: function searchTitle(state) {
    return state.search_title;
  }
};
var actions = (_actions = {}, _defineProperty(_actions, SET_UPLOAD_STATUS, function (context, param) {
  context.commit(SET_UPLOAD_STATUS, param);
}), _defineProperty(_actions, ERROR, function (context, param) {
  context.commit(SET_ERROR, param);
}), _defineProperty(_actions, MESSAGE, function (context, param) {
  context.commit(SET_MESSAGE, param);
}), _defineProperty(_actions, SEARCH_ENABLED, function (context, param) {
  context.commit(SET_SEARCH_ENABLED, param);
}), _defineProperty(_actions, SEARCH_TITLE, function (context, param) {
  context.commit(SET_SEARCH_TITLE, param);
}), _actions);
var mutations = (_mutations = {}, _defineProperty(_mutations, SET_UPLOAD_STATUS, function (state, status) {
  state.upload_status = status;
}), _defineProperty(_mutations, SET_ERROR, function (state, error) {
  if (error && isArray(error)) {
    state.errors = error.map(function (row) {
      row.timeout = toSafeInteger(process.env.VUE_APP_MESSAGE_TIMEOUT);
      return row;
    });
  } else {
    state.errors = [];
  }
}), _defineProperty(_mutations, SET_MESSAGE, function (state, message) {
  if (message && isArray(message)) {
    state.messages = message.map(function (row) {
      row.timeout = toSafeInteger(process.env.VUE_APP_MESSAGE_TIMEOUT);
      return row;
    });
  } else {
    state.messages = [];
  }
}), _defineProperty(_mutations, SET_SEARCH_ENABLED, function (state, status) {
  state.search_enabled = status;
}), _defineProperty(_mutations, SET_SEARCH_TITLE, function (state, title) {
  state.search_title = title;
}), _mutations);
export default {
  state: state,
  actions: actions,
  mutations: mutations,
  getters: getters
};