import _defineProperty from "D:/Development/Laravel/genic-drive-updated/frontend/node_modules/@babel/runtime/helpers/esm/defineProperty.js";

var _actions, _mutations;

// action types
export var UPDATE_PERSONAL_INFO = "updateUserPersonalInfo";
export var UPDATE_ACCOUNT_INFO = "updateUserAccountInfo"; // mutation types

export var SET_PERSONAL_INFO = "setPersonalInfo";
export var SET_ACCOUNT_INFO = "setAccountInfo";
var state = {
  user_personal_info: {
    first_name: "",
    profile: "",
    display_name: "",
    phone_number: "",
    email: ""
  },
  user_account_info: {
    username: "nick84",
    email: "***********",
    language: "English",
    time_zone: "(GMT-11:00) Midway Island",
    communication: {
      email: true,
      sms: true,
      phone: false
    },
    verification: true
  }
};
var getters = {
  currentUserPersonalInfo: function currentUserPersonalInfo(state) {
    return state.user_personal_info;
  },
  currentUserAccountInfo: function currentUserAccountInfo(state) {
    return state.user_account_info;
  },
  currentUserPhoto: function currentUserPhoto(state) {
    return state.user_personal_info.photo;
  }
};
var actions = (_actions = {}, _defineProperty(_actions, UPDATE_PERSONAL_INFO, function (context, payload) {
  context.commit(SET_PERSONAL_INFO, payload);
}), _defineProperty(_actions, UPDATE_ACCOUNT_INFO, function (context, payload) {
  context.commit(SET_ACCOUNT_INFO, payload);
}), _actions);
var mutations = (_mutations = {}, _defineProperty(_mutations, SET_PERSONAL_INFO, function (state, user_personal_info) {
  state.user_personal_info = user_personal_info;
}), _defineProperty(_mutations, SET_ACCOUNT_INFO, function (state, user_account_info) {
  state.user_account_info = user_account_info;
}), _mutations);
export default {
  state: state,
  actions: actions,
  mutations: mutations,
  getters: getters
};