import _defineProperty from "D:/Development/Laravel/genic-drive-updated/frontend/node_modules/@babel/runtime/helpers/esm/defineProperty.js";

var _actions, _mutations;

import "core-js/modules/es.object.to-string.js";
import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service"; // action types

export var LOGIN = "login";
export var LOGOUT = "logout";
export var FORGOT = "forgot"; // mutation types

export var PURGE_AUTH = "logOut";
export var SET_AUTH = "setUser";
export var SET_ERROR = "setError";
var state = {
  errors: [],
  user: JwtService.getCurrentUser(),
  isAuthenticated: !!JwtService.getToken()
};
var getters = {
  currentUser: function currentUser(state) {
    return state.user;
  },
  isAuthenticated: function isAuthenticated(state) {
    return state.isAuthenticated;
  }
};
var actions = (_actions = {}, _defineProperty(_actions, FORGOT, function (context, credentials) {
  return new Promise(function (resolve, reject) {
    ApiService.post("auth/forgot", credentials).then(function (_ref) {
      var data = _ref.data;
      context.commit(PURGE_AUTH);
      resolve(data);
    }).catch(function (_ref2) {
      var response = _ref2.response;
      context.commit(SET_ERROR, response.data.errors);
      reject(response);
    });
  });
}), _defineProperty(_actions, LOGIN, function (context, credentials) {
  return new Promise(function (resolve, reject) {
    ApiService.post("auth/login", credentials).then(function (_ref3) {
      var data = _ref3.data;
      context.commit(SET_AUTH, data);
      resolve(data);
    }).catch(function (_ref4) {
      var response = _ref4.response;
      context.commit(SET_ERROR, response.data.errors);
      reject(response);
    });
  });
}), _defineProperty(_actions, LOGOUT, function (context) {
  context.commit(PURGE_AUTH);
}), _actions);
var mutations = (_mutations = {}, _defineProperty(_mutations, SET_ERROR, function (state, error) {
  state.errors = error;
}), _defineProperty(_mutations, SET_AUTH, function (state, data) {
  state.isAuthenticated = true;
  state.user = data.user;
  state.errors = {};
  JwtService.saveToken(data);
}), _defineProperty(_mutations, PURGE_AUTH, function (state) {
  state.isAuthenticated = false;
  state.user = {};
  state.errors = {};
  JwtService.destroyToken();
}), _mutations);
export default {
  state: state,
  actions: actions,
  mutations: mutations,
  getters: getters
};