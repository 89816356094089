import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.json.stringify.js";
import { AES, enc } from "crypto-js";
import { isEmpty, isObject } from "lodash";
import { saveData, deleteData, getData, saveRawData, getRawData } from "@/core/services/local.service";
var ID_TOKEN_KEY = "access_token";
var ID_TOKEN_TYPE = "token_type";
var ID_TOKEN_EXPIRY = "expiry";
var ID_TOKEN_EXPIRY_AT = "expires_at";
var ID_CURRENT_USER = "_btusr_tmp";
var ID_MENU_LIST = "_btcsr_tmp";
var ID_PERMISSION_LIST = "_btpsr_tmp";
var ID_CLIENT_DETAILS = "_btcld_tmp";
var ENCRYPTION_KEY = process.env.VUE_APP_ENCRYPTION_KEY;

var destroyAuth = function destroyAuth() {
  deleteData(ID_TOKEN_KEY);
  deleteData(ID_TOKEN_TYPE);
  deleteData(ID_TOKEN_EXPIRY);
  deleteData(ID_TOKEN_EXPIRY_AT);
  deleteData(ID_CURRENT_USER);
  deleteData(ID_MENU_LIST);
  deleteData(ID_PERMISSION_LIST);
  deleteData(ID_CLIENT_DETAILS);
};

export var saveLocalStorage = function saveLocalStorage(key, data) {
  try {
    if (isEmpty(data) === false && isObject(data)) {
      var encrypted = AES.encrypt(JSON.stringify(data), ENCRYPTION_KEY).toString();
      saveData(key, encrypted);
    }
  } catch (error) {
    console.warn({
      error: error
    });
  }
};
export var getLocalStorage = function getLocalStorage(key) {
  try {
    var encrypted = getData(key);

    if (!encrypted) {
      return null;
    }

    var decrypted = AES.decrypt(encrypted, ENCRYPTION_KEY);
    return JSON.parse(decrypted.toString(enc.Utf8));
  } catch (error) {
    console.warn({
      error: error
    });
  }
};
export var isAuthenticated = function isAuthenticated() {
  return !!getRawData(ID_TOKEN_KEY);
};
export var getToken = function getToken() {
  return getRawData(ID_TOKEN_KEY);
};
export var getTokenType = function getTokenType() {
  return getRawData(ID_TOKEN_TYPE);
};
export var getTokenExpiry = function getTokenExpiry() {
  return getRawData(ID_TOKEN_EXPIRY);
};
export var getCurrentUser = function getCurrentUser() {
  return getLocalStorage(ID_CURRENT_USER);
};
export var saveToken = function saveToken(_ref) {
  var user = _ref.user,
      type = _ref.type,
      expiry = _ref.expiry,
      token = _ref.token;
  saveRawData(ID_TOKEN_TYPE, type);
  saveRawData(ID_TOKEN_KEY, token);
  saveRawData(ID_TOKEN_EXPIRY, expiry);
  saveLocalStorage(ID_CURRENT_USER, user);
};
export var destroyToken = function destroyToken() {
  destroyAuth();
};
export default {
  getToken: getToken,
  getTokenType: getTokenType,
  getTokenExpiry: getTokenExpiry,
  getCurrentUser: getCurrentUser,
  saveToken: saveToken,
  saveLocalStorage: saveLocalStorage,
  getLocalStorage: getLocalStorage,
  isAuthenticated: isAuthenticated,
  destroyToken: destroyToken
};