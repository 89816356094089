import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.function.name.js";
export var getData = function getData(key) {
  try {
    var userString = window.localStorage.getItem(key);
    var userArr = JSON.parse(userString);
    return userArr;
  } catch (err) {
    return new Object();
  }
};
export var deleteData = function deleteData(key) {
  window.localStorage.removeItem(key);
};
export var saveData = function saveData(key, data) {
  try {
    window.localStorage.removeItem(key);
    window.localStorage.setItem(key, JSON.stringify(data));
  } catch (e) {
    if (e.name === "QuotaExceededError") {
      window.localStorage.clear();
      window.location.reload(true);
    }
  }
};
export var saveRawData = function saveRawData(key, data) {
  try {
    window.localStorage.removeItem(key);
    window.localStorage.setItem(key, data);
  } catch (e) {
    if (e.name === "QuotaExceededError") {
      window.localStorage.clear();
      window.location.reload(true);
    }
  }
};
export var getRawData = function getRawData(key) {
  return window.localStorage.getItem(key);
};
export default {
  saveData: saveData,
  getData: getData,
  deleteData: deleteData,
  saveRawData: saveRawData,
  getRawData: getRawData
};