import { isObject, isEmpty, identity, pickBy, capitalize, toLower, cloneDeep } from "lodash";
import { ERROR } from "@/core/services/store/common.module";
import Moment from "moment-timezone";

Moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

const validateEmail = (email) => {
	//eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
};

class MainSetting {
	install(Vue) {
		Vue.mixin({
			data() {
				return {
					today: null,
					uniqFieldId: null,
					vrules: {
						required(val, field, isInteger) {
							if (isObject(val) && isEmpty(val)) {
								return `${field} is required`;
							}
							if (isInteger && parseInt(val) <= 0) {
								return `${field} is required.`;
							}
							return !!val || `${field} is required`;
						},
						validEmail(val, field) {
							if (val) {
								if (validateEmail(val)) {
									return true;
								}
								return `${field} must be valid`;
							}
							return true;
						},
					},
				};
			},
			methods: {
				humanFileSize(sizeInKb) {
					const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
			    let size = sizeInKb;
			    let unitIndex = 0;

			    while (size >= 1000 && unitIndex < units.length - 1) {
			        size /= 1000;
			        unitIndex++;
			    }

			    const formattedSize = size.toFixed(0);

			    return `${formattedSize} ${units[unitIndex]}`;
				},
				goBackStepper() {
					this.stepper--;
				},
				deepEmpty(param) {
					return cloneDeep(pickBy(param, identity));
				},
				isNotEmpty(param) {
					return !isEmpty(param);
				},
				formatBytes(bytes, decimals = 2) {
					if (bytes === 0) return "0 Bytes";

					const k = 1000;
					const dm = decimals < 0 ? 0 : decimals;
					const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

					const i = Math.floor(Math.log(bytes) / Math.log(k));

					return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
				},
				formatDateTime(dateTime) {
					if (!dateTime) {
						return Moment().format("DD/MM/YYYY hh:mm a");
					}
					return Moment(dateTime).format("DD/MM/YYYY hh:mm a");
				},
				formatDateTimeRaw(dateTime) {
					if (!dateTime) {
						return Moment().format("YYYY-MM-DD HH:mm");
					}
					return Moment(dateTime).format("YYYY-MM-DD HH:mm");
				},
				formatDate(date) {
					if (!date) {
						return Moment().format("DD/MM/YYYY");
					}
					return Moment(date).format("DD/MM/YYYY");
				},
				formatDateRaw(date) {
					if (!date) {
						return Moment().format("YYYY-MM-DD");
					}
					return Moment(date).format("YYYY-MM-DD");
				},
				validateForm(formRef) {
					this.$store.dispatch(ERROR, []);
					const formErrors = [];
					const { inputs } = formRef;
					for (let i = 0; i < inputs.length; i++) {
						const { errorBucket } = inputs[i];
						for (let z = 0; z < errorBucket.length; z++) {
							const errorMessage = capitalize(toLower(errorBucket[z]));
							formErrors.push({
								model: true,
								message: errorMessage,
							});
						}
					}
					return formErrors;
				},
				goBack() {
					this.$router.go(-1);
				},
				async logError(error) {
					try {
						console.warn(error);
					} catch (error) {
						console.warn(error);
					}
				},
				get_icon(row) {
					if (row.type == "file") {
						return `mdi-file-${row.extension}`;
					}

					if (row.type == "folder") {
						return `mdi-folder`;
					}
				},
				stringUnique() {
					let result = "";
					let characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
					let charactersLength = characters.length;
					for (let i = 0; i < 10; i++) {
						result += characters.charAt(Math.floor(Math.random() * charactersLength));
					}
					return result;
				},
				isAlphaNumeric(event) {
					let char = String.fromCharCode(event.keyCode);
					if (/^[a-zA-Z0-9 ]+$/.test(char)) return true;
					else event.preventDefault();
				},
			},
			mounted() {
				this.today = Moment().format("YYYY-MM-DD");
				this.uniqFieldId = this.stringUnique();
			},
			errorCaptured(err, vm, info) {
				this.logError({ err, vm, info });
			},
		});
	}
}

export default new MainSetting();
