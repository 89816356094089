import ApiService from "@/core/services/api.service";

// action types
export const POST = "requestPost";
export const GET = "requestGet";
export const QUERY = "requestQuery";
export const PUT = "requestPut";
export const DELETE = "requestDelete";
export const PATCH = "requestPatch";
export const UPLOAD = "requestUpload";

// mutation types
export const SET_DATA = "setData";

const state = {
	data: [],
	request_errors: [],
};

const getters = {
	data(state) {
		state.data;
	},
};

const actions = {
	[POST](context, { url, data }) {
		return new Promise((resolve, reject) => {
			ApiService.setHeader();
			ApiService.post(url, data)
				.then(({ data }) => {
					context.commit(SET_DATA, data);
					resolve(data);
				})
				.catch(({ response }) => {
					reject(response);
				});
		});
	},
	[UPLOAD](context, { url, data }) {
		return new Promise((resolve, reject) => {
			ApiService.setHeader();
			ApiService.upload(url, data)
				.then(({ data }) => {
					context.commit(SET_DATA, data);
					resolve(data);
				})
				.catch(({ response }) => {
					reject(response);
				});
		});
	},
	[QUERY](context, { url, data }) {
		return new Promise((resolve, reject) => {
			ApiService.setHeader();
			ApiService.query(url, data)
				.then(({ data }) => {
					context.commit(SET_DATA, data);
					resolve(data);
				})
				.catch(({ response }) => {
					reject(response);
				});
		});
	},
	[GET](context, { url }) {
		return new Promise((resolve, reject) => {
			ApiService.setHeader();
			ApiService.get(url)
				.then(({ data }) => {
					context.commit(SET_DATA, data);
					resolve(data);
				})
				.catch(({ response }) => {
					reject(response);
				});
		});
	},
	[PUT](context, { url, data }) {
		return new Promise((resolve, reject) => {
			ApiService.setHeader();
			ApiService.put(url, data)
				.then(({ data }) => {
					context.commit(SET_DATA, data);
					resolve(data);
				})
				.catch(({ response }) => {
					reject(response);
				});
		});
	},
	[DELETE](context, { url, data }) {
		return new Promise((resolve, reject) => {
			ApiService.setHeader();
			ApiService.delete(url, data)
				.then(({ data }) => {
					context.commit(SET_DATA, data);
					resolve(data);
				})
				.catch(({ response }) => {
					reject(response);
				});
		});
	},
	[PATCH](context, { url, data }) {
		return new Promise((resolve, reject) => {
			ApiService.setHeader();
			ApiService.patch(url, data)
				.then(({ data }) => {
					context.commit(SET_DATA, data);
					resolve(data);
				})
				.catch(({ response }) => {
					reject(response);
				});
		});
	},
};

const mutations = {
	[SET_DATA](state, data) {
		state.data = data;
	},
};

export default {
	state,
	actions,
	mutations,
	getters,
};
